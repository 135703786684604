var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.setTypeDialogVisible)?_c('popup',{staticClass:"statistical-classifi-visible",on:{"closeEvent":_vm.closeEvent}},[_c('div',{attrs:{"slot":"popup-name"},slot:"popup-name"},[_vm._v(" "+_vm._s(_vm.$t("statisticsUp.questionConsultation.setQuestionType"))+" ")]),_c('div',{attrs:{"slot":"popup-con"},slot:"popup-con"},[_c('div',{staticClass:"popup-container-self"},[_c('div',{staticClass:"list-box"},[_c('div',{staticClass:"title-top"},[_c('div',{staticClass:"left-title-top"},[_vm._v(" "+_vm._s(_vm.$t("statisticsUp.setTypeDialogTip1"))+" ")]),_c('div',{staticClass:"right-title-top"},[_vm._v(" "+_vm._s(_vm.$t("statisticsUp.setTypeDialogTip2"))+" ")])]),_c('div',{staticClass:"scroll-box"},_vm._l((_vm.list),function(cell,index){return _c('div',{key:index,staticClass:"item-statistical-classifi"},[_c('el-input',{attrs:{"placeholder":_vm.$t('statisticsUp.quesTypePlaceholder')},model:{value:(cell.name),callback:function ($$v) {_vm.$set(cell, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"cell.name"}}),_c('el-cascader',{key:'list_' + index,attrs:{"rows":3,"options":_vm.intentTree4Radio,"show-all-levels":false,"placeholder":_vm.$t('statisticsUp.selectIntent'),"props":{
                                    emitPath: false,
                                    label: 'name',
                                    value: 'id',
                                    rows: '3',
                                    multiple: true,
                                    checkStrictly: true,
                                },"collapse-tags":"","filterable":""},model:{value:(cell.intentIds),callback:function ($$v) {_vm.$set(cell, "intentIds", $$v)},expression:"cell.intentIds"}}),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.list.length > 1),expression:"list.length > 1"}],staticClass:"el-icon-delete cursor",on:{"click":function($event){return _vm.deleteSetType(index)}}})],1)}),0),_c('div',{staticClass:"add-classifi-btn"},[_c('div',{staticClass:"add-btn guoran-tongyichicun-16-13-xinjian iconfont",on:{"click":_vm.addType}})])])])]),_c('div',{attrs:{"slot":"dialog-footer"},slot:"dialog-footer"},[_c('el-button',{staticClass:"cancel-btn",attrs:{"plain":"","round":""},on:{"click":_vm.cancelSaveSetType}},[_vm._v(_vm._s(_vm.$t("common.cancel")))]),_c('el-button',{staticClass:"confirm-btn",staticStyle:{"padding":"0 !important"},attrs:{"type":"primary","round":""},on:{"click":function($event){return _vm.saveSetType('')}}},[_vm._v(_vm._s(_vm.$t("common.confirm")))])],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
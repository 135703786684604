<template>
    <div id="questionssolution">
        <div class="asksta-simple-title">
            <div class="ast-left" :style="$i18n.locale === 'en' ? 'width:320px' : ''">
                <span class="ast-left-name">{{$t('statisticsUp.questionSolution.title')}}</span>
                <span class="ast-left-icon">
                    <el-tooltip class="item" effect="dark"  placement="top-start">
                        <div slot="content">
                            <div style="font-weight:700;">{{$t('statisticsUp.questionSolution.totalNumberOfSessions')}}：</div>
                            <div>{{$t('statisticsUp.questionSolution.tip1')}}</div>
                            <div>{{$t('statisticsUp.questionSolution.tip2')}}</div>
                            <div>{{$t('statisticsUp.questionSolution.tip3')}}</div>
                            <div class="static-empty-dom"></div>
                            <div style="font-weight:700;">{{$t('statisticsUp.questionSolution.botSolvingSession')}}：</div>
                            <div>{{$t('statisticsUp.questionSolution.tip4')}}</div>
                            <div>{{$t('statisticsUp.questionSolution.tip5')}}</div>
                            <div class="static-empty-dom"></div>
                            <div style="font-weight:700;">{{$t('statisticsUp.questionSolution.TransferOrderResolveSession')}}：</div>
                            <div>{{$t('statisticsUp.questionSolution.tip6')}}</div>
                            <div>{{$t('statisticsUp.questionSolution.tip7')}}</div>
                            <div class="static-empty-dom"></div>
                            <div style="font-weight:700;">{{$t('statisticsUp.questionSolution.TransferIMResolveSession')}}：</div>
                            <div>{{$t('statisticsUp.questionSolution.tip8')}}</div>
                            <div>{{$t('statisticsUp.questionSolution.tip9')}}</div>
                            <div class="static-empty-dom"></div>
                            <div style="font-weight:700;">{{$t('statisticsUp.questionSolution.totalQuestion')}}：</div>
                            <div>{{$t('statisticsUp.questionSolution.tip10')}}</div>
                            <div class="static-empty-dom"></div>
                            <div style="font-weight:700;">{{$t('statisticsUp.questionSolution.botSolvingQuestion')}}：</div>
                            <div>{{$t('statisticsUp.questionSolution.tip11')}}</div>
                            <div class="static-empty-dom"></div>
                            <div style="font-weight:700;">{{$t('statisticsUp.questionSolution.transferOrderResolveQuestion')}}：</div>
                            <div>{{$t('statisticsUp.questionSolution.tip12')}}</div>
                            <div class="static-empty-dom"></div>
                            <div style="font-weight:700;">{{$t('statisticsUp.questionSolution.TransferIMResolveQuestion')}}：</div>
                            <div>{{$t('statisticsUp.questionSolution.tip13')}}</div>
                        </div>
                        <em class="el-icon-warning-outline"></em>
                    </el-tooltip>
                </span>
            </div>
            <div class="ast-right" v-if="sessionTotalData.totalNum != 0 || questionTotalData.totalNum != 0">
                <div class="ast-right-top">
                    <div class="ast-right-cell ast-right-cell-bg-lb ast-right-cell-bg-lb-session"  :style="{'margin-right':mr + 'px',width:typeof(tipsWidth) === 'string' ? tipsWidth :  tipsWidth + 'px'}">
                        <span  class="arc-icon total-session"></span>
                        <span  class="arc-name">{{$t('statisticsUp.questionSolution.totalNumberOfSessions')}}:</span>
                        <span class="arc-num">{{sessionTotalData.totalNum}}</span>
                        <span class="arc-per-pre">{{$t('statisticsUp.ratio')}}</span>
                        <ration-text :data="sessionTotalData"></ration-text>
                    </div>
                    <div class="ast-right-cell">
                        <span @click="switchItem(1)" :class="['arc-icon',topActive1 ? 'color-b-08' : 'color-gray']"></span>
                        <span @click="switchItem(1)" class="arc-name">{{$t('statisticsUp.questionSolution.botSolvingSession')}}:</span>
                        <span class="arc-num">{{botSolveSessionData.totalNum}}</span>
                        <span class="arc-per-pre">{{$t('statisticsUp.ratio')}}</span>
                        <ration-text :data="botSolveSessionData"></ration-text>
                    </div>

                    <div class="ast-right-cell" v-if="transWorkorderIsShow">
                        <span @click="switchItem(2)" :class="['arc-icon',topActive2 ? 'color-b-04' : 'color-gray']"></span>
                        <span @click="switchItem(2)" class="arc-name">{{$t('statisticsUp.questionSolution.TransferOrderResolveSession')}}:</span>
                        <span class="arc-num">{{workerSolveSessionData.totalNum}}</span>
                        <span class="arc-per-pre">{{$t('statisticsUp.ratio')}}</span>
                        <ration-text :data="workerSolveSessionData"></ration-text>
                    </div>
                    <div class="ast-right-cell" v-if="transImIsShow">
                        <span @click="switchItem(3)" :class="['arc-icon',topActive3 ? 'color-b-02' : 'color-gray']"></span>
                        <span @click="switchItem(3)" class="arc-name">{{$t('statisticsUp.questionSolution.TransferIMResolveSession')}}:</span>
                        <span class="arc-num">{{IMSolveSessionData.totalNum}}</span>
                        <span class="arc-per-pre">{{$t('statisticsUp.ratio')}}</span>
                        <ration-text :data="IMSolveSessionData"></ration-text>
                    </div>
                </div>
                <div class="ast-right-bottom">
                    <div class="ast-right-cell ast-right-cell-bg-lp ast-right-cell-bg-lp-question" :style="{'margin-right':mr2 + 'px',width:typeof(tipsWidth) === 'string' ? tipsWidth :  tipsWidth + 'px'}">
                        <span  :class="['arc-icon','total-question']"></span>
                        <span  class="arc-name">{{$t('statisticsUp.questionSolution.totalQuestion')}}:</span>
                        <span class="arc-num">{{questionTotalData.totalNum}}</span>
                        <span class="arc-per-pre">{{$t('statisticsUp.ratio')}}</span>
                        <ration-text :data="questionTotalData"></ration-text>
                    </div>
                    <div class="ast-right-cell">
                        <span @click="switchItem(4)" :class="['arc-icon',bottomActive1 ? 'color-p-08' : 'color-gray']"></span>
                        <span @click="switchItem(4)" class="arc-name">{{$t('statisticsUp.questionSolution.botSolvingQuestion')}}:</span>
                        <span class="arc-num">{{botSolveQuestionData.totalNum}}</span>
                        <span class="arc-per-pre">{{$t('statisticsUp.ratio')}}</span>
                        <ration-text :data="botSolveQuestionData"></ration-text>
                    </div>

                    <div class="ast-right-cell" v-if="transWorkorderIsShow">
                        <span @click="switchItem(5)" :class="['arc-icon',bottomActive2 ? 'color-p-04' : 'color-gray']"></span>
                        <span @click="switchItem(5)" class="arc-name">{{$t('statisticsUp.questionSolution.transferOrderResolveQuestion')}}:</span>
                        <span class="arc-num">{{workerSolveQuestionData.totalNum}}</span>
                        <span class="arc-per-pre">{{$t('statisticsUp.ratio')}}</span>
                        <ration-text :data="workerSolveQuestionData"></ration-text>
                    </div>
                    <div class="ast-right-cell" v-if="transImIsShow">
                        <span @click="switchItem(6)" :class="['arc-icon',bottomActive3 ? 'color-p-02' : 'color-gray']"></span>
                        <span @click="switchItem(6)" class="arc-name">{{$t('statisticsUp.questionSolution.TransferIMResolveQuestion')}}:</span>
                        <span class="arc-num">{{IMSolveQuestionData.totalNum}}</span>
                        <span class="arc-per-pre">{{$t('statisticsUp.ratio')}}</span>
                        <ration-text :data="IMSolveQuestionData"></ration-text>
                    </div>
                </div>
            </div>
        </div>
        <div style="height: 300px" v-if="sessionTotalData.totalNum == 0 && questionTotalData.totalNum == 0">
            <no-data-echarts ></no-data-echarts>
        </div>
        <div class="asksta-receivenum-char" >
            <div style="height: 300px" id="asksta-questionssolution-char"></div>
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
import RationText from './ratio-text.vue'
import NoDataEcharts from './noDataEcharts.vue'
export default {
    data() {
        return {
            topActive1: true,
            topActive2: false,
            topActive3: false,
            bottomActive1: true,
            bottomActive2: false,
            bottomActive3: false,
            myChart: null,
            width:200,
            showArr:[],
            bottomHaveData:0,
            sessionTotalData:{
                totalNum:0,
                ratio:'0.00%',
                ratioType:"0",
            },
            questionTotalData:{
                totalNum:0,
                ratio:'0.00%',
                ratioType:"0",
            },
            botSolveSessionData:{
                totalNum:0,
                ratio:'0.00%',
                ratioType:"0",
                dataDetailDtoList:[]
            },
            IMSolveSessionData:{
                totalNum:0,
                ratio:'0.00%',
                ratioType:"0",
                dataDetailDtoList:[]
            },
            workerSolveSessionData:{
                totalNum:0,
                ratio:'0.00%',
                ratioType:"0",
                dataDetailDtoList:[]
            },
            botSolveQuestionData:{
                totalNum:0,
                ratio:'0.00%',
                ratioType:"0",
                dataDetailDtoList:[]
            },
            IMSolveQuestionData:{
                totalNum:0,
                ratio:'0.00%',
                ratioType:"0",
                dataDetailDtoList:[]
            },
            workerSolveQuestionData:{
                totalNum:0,
                ratio:'0.00%',
                ratioType:"0",
                dataDetailDtoList:[]
            },
            mr:0,
            mr2:0,
            tipsWidth:'auto'
        }
    },
    components:{RationText,NoDataEcharts},
    props:{
        questionsSolutionData:{
            type:Object,
            default(){
                return {}
            }
        },
        loading:{
            type:Boolean,
            default:true
        },
        isInit:{
            type:Boolean,
            default:true
        },
        transWorkorderIsShow:{
            type:Boolean,
            default:true
        },
        transImIsShow:{
            type:Boolean,
            default:true
        }
    },
    watch:{
        isInit:{
            handler(n){
                if(n){

                      // this.showArr = [1, 2, 3,4,5,6];
                }
            },
            immediate:true
        },
        questionsSolutionData:{
            handler(n){
                console.log(n,'nnn-questionsSolutionData');
                if(!this.loading){
                    let list = [1,4];
                    if(this.transWorkorderIsShow){
                        this.topActive2 = true
                        this.bottomActive2 = true
                        list = [...list,...[2,5]]
                    } else {
                        this.topActive2 = false
                        this.bottomActive2 = false
                    }
                    if(this.transImIsShow){
                        this.topActive3 = true
                        this.bottomActive3 = true
                        list = [...list,...[3,6]]
                    }else {
                        this.topActive3 = false
                        this.bottomActive3 = false
                    }
                    this.showArr = list;
                    this.handlerData(n);
                    this.$forceUpdate()
                }


            },
            deep:true
        },
    },
    methods: {
        // 处理数据
        handlerData(n){
            if(n.sourceData && n.sourceData.sessionQuestionList && n.sourceData.sessionQuestionList.length > 0){
                // console.log( n.sourceData.sessionQuestionList,'数据');
                n.sourceData.sessionQuestionList.forEach((item,index) => {
                    if(item.type === 'SESSION'){
                        this.sessionTotalData = {
                            totalNum:item.totalNum,
                            ratio:item.ratio && item.ratio !== '0%' ? item.ratio : '0.00%',
                            ratioType: item.ratio.charAt(0),
                        }
                    } else if(item.type === 'QUESTION'){
                        this.questionTotalData = {
                            totalNum:item.totalNum,
                            ratio:item.ratio && item.ratio !== '0%' ? item.ratio : '0.00%',
                            ratioType: item.ratio.charAt(0),
                        }
                    } else if(item.type === 'BOT_SOLVE_SESSION'){
                        this.botSolveSessionData = {
                            totalNum:item.totalNum,
                            ratio:item.ratio && item.ratio !== '0%' ? item.ratio : '0.00%',
                            ratioType: item.ratio.charAt(0),
                            dataDetailDtoList:item.dataDetailDtoList,
                        }
                    } else if(item.type === 'IM_SOLVE_SESSION'){
                        this.IMSolveSessionData = {
                            totalNum:item.totalNum,
                            ratio:item.ratio && item.ratio !== '0%' ? item.ratio : '0.00%',
                            ratioType: item.ratio.charAt(0),
                            dataDetailDtoList:item.dataDetailDtoList,
                        }
                    } else if(item.type === 'WORKORDER_SOLVE_SESSION'){
                        this.workerSolveSessionData = {
                            totalNum:item.totalNum,
                            ratio:item.ratio && item.ratio !== '0%' ? item.ratio : '0.00%',
                            ratioType: item.ratio.charAt(0),
                            dataDetailDtoList:item.dataDetailDtoList,
                        }
                    } else if(item.type === 'BOT_SOLVE_QUESTION'){
                        this.botSolveQuestionData = {
                            totalNum:item.totalNum,
                            ratio:item.ratio && item.ratio !== '0%' ? item.ratio : '0.00%',
                            ratioType: item.ratio.charAt(0),
                            dataDetailDtoList:item.dataDetailDtoList,
                        }
                    } else if(item.type === 'IM_SOLVE_QUESTION'){
                        this.IMSolveQuestionData = {
                            totalNum:item.totalNum,
                            ratio:item.ratio && item.ratio !== '0%' ? item.ratio : '0.00%',
                            ratioType: item.ratio.charAt(0),
                            dataDetailDtoList:item.dataDetailDtoList,
                        }
                    } else if(item.type === 'WORKORDER_SOLVE_QUESTION'){
                        this.workerSolveQuestionData = {
                            totalNum:item.totalNum,
                            ratio:item.ratio && item.ratio !== '0%' ? item.ratio : '0.00%',
                            ratioType: item.ratio.charAt(0),
                            dataDetailDtoList:item.dataDetailDtoList,
                        }
                    }
                })
                // console.log(this.botSolveSessionData,this.workerSolveSessionData,this.IMSolveSessionData,'上部分');
                // console.log(this.botSolveQuestionData,this.workerSolveQuestionData,this.IMSolveQuestionData,'下部分');
                this.$nextTick(() => {
                    // let topWidth = (document.querySelector('.ast-right-cell-bg-lb').style.width).replace('px',"");
                    // let bottomWidth = (document.querySelector('.ast-right-cell-bg-lb').style.width).replace('px',"");
                    // console.log(topWidth,bottomWidth,'---');
                    // console.log(document.querySelector('.ast-right-cell-bg-lb').style.width);
                    // if(Number(topWidth) > Number(bottomWidth)){
                    //     this.width = Number(topWidth);
                    // }
                    // if(Number(bottomWidth) > Number(topWidth)){
                    //     this.width = Number(bottomWidth);
                    // }
                    // if(Number(bottomWidth) === Number(topWidth)){
                    //     this.width = Number(bottomWidth);
                    // }
                    // console.log(this.width,'width');
                    this.tipsWidth = 'auto';
                    setTimeout(() => {
                        this.$nextTick(() => {
                            if(!(this.sessionTotalData.totalNum === 0 && this.questionTotalData.totalNum === 0)){
                                let top = document.querySelector('.ast-right-cell-bg-lb-session').clientWidth;
                                let bottom = document.querySelector('.ast-right-cell-bg-lp-question').clientWidth;
                                if(top > bottom){
                                    this.tipsWidth = top - 12;
                                } else {
                                    this.tipsWidth = bottom - 12;
                                }
                                this.mr = Math.abs(210 - this.tipsWidth) + 16
                                this.mr2 = Math.abs(210 - this.tipsWidth) + 16

                                //  this.mr = Math.abs(330 - this.tipsWidth) + 28
                                // this.mr2 = Math.abs(330 - this.tipsWidth) + 28
                            }
                        })
                    },100)
                })


                 this.iniEchart(this.showArr)
                // this.iniEchart(this.isInit ? [1, 2, 3,4,5,6] : this.showArr);
            } else {
                this.sessionTotalData = {
                    totalNum:0,
                    ratio:'0.00%',
                    ratioType:"0",
                }
                this.questionTotalData = {
                    totalNum:0,
                    ratio:'0.00%',
                    ratioType:"0",
                }
            }
        },
        switchItem(index) {
            if (index == 1) {
                if(this.showArr.length === 1 && this.topActive1){
                    this.$message.warning(this.$t('statisticsUp.keepAtLeastOne'));
                    return false;
                } else {
                    this.topActive1 = !this.topActive1;
                }

            }
            if (index == 2) {
                if(this.showArr.length === 1 && this.topActive2){
                    this.$message.warning(this.$t('statisticsUp.keepAtLeastOne'));
                    return false;
                } else {
                    this.topActive2 = !this.topActive2;
                }
            }
            if (index == 3) {
                if(this.showArr.length === 1 && this.topActive3){
                    this.$message.warning(this.$t('statisticsUp.keepAtLeastOne'));
                    return false;
                } else {
                    this.topActive3 = !this.topActive3;
                }
            }
            if (index == 4) {
                if(this.showArr.length === 1 && this.bottomActive1){
                    this.$message.warning(this.$t('statisticsUp.keepAtLeastOne'));
                    return false;
                } else {
                    this.bottomActive1 = !this.bottomActive1;
                }
            }
            if (index == 5) {
                if(this.showArr.length === 1 && this.bottomActive2){
                    this.$message.warning(this.$t('statisticsUp.keepAtLeastOne'));
                    return false;
                } else {
                    this.bottomActive2 = !this.bottomActive2;
                }
            }
            if (index == 6) {
                if(this.showArr.length === 1 && this.bottomActive3){
                    this.$message.warning(this.$t('statisticsUp.keepAtLeastOne'));
                    return false;
                } else {
                    this.bottomActive3 = !this.bottomActive3;
                }
            }

            let arr = [];
            if (this.topActive1) {
                arr.push(1);
            }
            if (this.topActive2) {
                arr.push(2);
            }
            if (this.topActive3) {
                arr.push(3);
            }
            if (this.bottomActive1) {
                arr.push(4);
            }
            if (this.bottomActive2) {
                arr.push(5);
            }
            if (this.bottomActive3) {
                arr.push(6);
            }
            this.showArr = arr;
            this.iniEchart(arr);
        },
        handlerBorderRadis(list){
            // 只有一个类型时 , 四个角设置为圆角
            if(list.length === 1){
                list[0].itemStyle = {
                    normal: {
                        //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                        barBorderRadius: [50, 50, 50, 50]
                    }
                }
            } else if (list.length === 2){
                // 有多个类型时
                list[0].itemStyle = {
                    normal: {
                        //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                       barBorderRadius: [0, 0, 50, 50]
                    }
                }
                list[1].itemStyle = {
                    normal: {
                        //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                        barBorderRadius: [50, 50, 0, 0]
                    }
                }
            }  else if (list.length === 3){
                // 有多个类型时
                list[0].itemStyle = {
                    normal: {
                        //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                        barBorderRadius: [0, 0, 50, 50]
                    }
                }
                list[2].itemStyle = {
                    normal: {
                        //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                        barBorderRadius: [50, 50, 0, 0]
                    }
                }
            }
            return list;
        },
        iniEchart(showArr) {
            var chartDom1 = document.getElementById('asksta-questionssolution-char');
            if (this.myChart != null) {
                this.myChart.clear();
            }
            this.myChart = echarts.init(chartDom1);
            let xAxisData = [],topData1 = [],topData2 = [],topData3 = [],bottomData1 = [],bottomData2 = [],bottomData3 = [];
            this.botSolveSessionData.dataDetailDtoList && this.botSolveSessionData.dataDetailDtoList.length > 0 && this.botSolveSessionData.dataDetailDtoList.forEach((item,index) => {
                xAxisData.push(item.time);
                // topData1.push(item.num);
                topData1.push({
                    value:item.num
                })
            })
            this.workerSolveSessionData.dataDetailDtoList && this.workerSolveSessionData.dataDetailDtoList.length > 0 && this.workerSolveSessionData.dataDetailDtoList.forEach((item,index) => {
                // topData2.push(item.num);
                topData2.push({
                    value:item.num
                })
            })
            this.IMSolveSessionData.dataDetailDtoList && this.IMSolveSessionData.dataDetailDtoList.length > 0 && this.IMSolveSessionData.dataDetailDtoList.forEach((item,index) => {
                // topData3.push(item.num);
                topData3.push({
                    value:item.num
                })
            })
            this.botSolveQuestionData.dataDetailDtoList && this.botSolveQuestionData.dataDetailDtoList.length > 0 && this.botSolveQuestionData.dataDetailDtoList.forEach((item,index) => {
                // bottomData1.push(item.num);
                bottomData1.push({
                    value:item.num
                })
            })
            this.workerSolveQuestionData.dataDetailDtoList && this.workerSolveQuestionData.dataDetailDtoList.length > 0 && this.workerSolveQuestionData.dataDetailDtoList.forEach((item,index) => {
                // bottomData2.push(item.num);
                bottomData2.push({
                    value:item.num
                })
            })
            this.IMSolveQuestionData.dataDetailDtoList && this.IMSolveQuestionData.dataDetailDtoList.length > 0 && this.IMSolveQuestionData.dataDetailDtoList.forEach((item,index) => {
                // bottomData3.push(item.num);
                bottomData3.push({
                    value:item.num
                })
            })

            var emphasisStyle = {
                // itemStyle: {
                //     shadowBlur: 10,
                // }
            };
            let askSeries = [
                    {
                        name: this.$t('statisticsUp.questionSolution.botSolvingSession'),
                        type: 'bar',
                        stack: 'one',
                        barMaxWidth: '20px',
                        emphasis: emphasisStyle,
                        data: topData1,
                        itemStyle: {//自定义颜色
                            normal:{
                                color: "#458FFF"
                            },
                        },
                    },
                    {
                        name: this.$t('statisticsUp.questionSolution.TransferOrderResolveSession'),
                        type: 'bar',
                        stack: 'one',
                        barMaxWidth: '20px',
                        emphasis: emphasisStyle,
                        data: topData2,
                        itemStyle: {//自定义颜色
                            normal:{
                                color: "#89B8FF"
                            },
                        },
                    },
                    {
                        name: this.$t('statisticsUp.questionSolution.TransferIMResolveSession'),
                        type: 'bar',
                        stack: 'one',
                        barMaxWidth: '20px',
                        emphasis: emphasisStyle,
                        data: topData3,
                        itemStyle: {//自定义颜色
                            normal:{
                                color: "#BBD6FF"
                            },
                        },
                    },
                    {
                        name: this.$t('statisticsUp.questionSolution.botSolvingQuestion'),
                        type: 'bar',
                        stack: 'two',
                        barMaxWidth: '20px',
                        emphasis: emphasisStyle,
                        data: bottomData1,
                        itemStyle: {//自定义颜色
                            normal:{
                                color: "#FF8F97"
                            },
                        },
                    },
                    {
                        name: this.$t('statisticsUp.questionSolution.transferOrderResolveQuestion'),
                        type: 'bar',
                        stack: 'two',
                        barMaxWidth: '20px',
                        emphasis: emphasisStyle,
                        data: bottomData2,
                        itemStyle: {//自定义颜色
                            normal:{
                                color: "#FFBABF"
                            },
                        },
                    },
                    {
                        name: this.$t('statisticsUp.questionSolution.TransferIMResolveQuestion'),
                        type: 'bar',
                        stack: 'two',
                        barMaxWidth: '20px',
                        emphasis: emphasisStyle,
                        data: bottomData3,
                        itemStyle: {//自定义颜色
                            normal:{
                                color: "#FFDDDF"
                            },
                        },
                    }
            ]

            let series = [], color = [];
            if (showArr.indexOf(1) != -1) {
                series.push(askSeries[0])
                // color.push('#458FFF')
            }
            if (showArr.indexOf(2) != -1) {
                series.push(askSeries[1])
                // color.push('#89B8FF')
            }
            if (showArr.indexOf(3) != -1) {
                series.push(askSeries[2])
                // color.push('#BBD6FF')
            }
            if (showArr.indexOf(4) != -1) {
                series.push(askSeries[3])
                // color.push('#FF8F97')
            }
            if (showArr.indexOf(5) != -1) {
                series.push(askSeries[4])
                // color.push('#FFBABF')
            }
            if (showArr.indexOf(6) != -1) {
                series.push(askSeries[5])
                // color.push('#FFDDDF')
            }
            // console.log(series,'series');
            // 处理  首尾圆角
            let oneList = [],twoList = [];
            series.forEach((seriesItem,seriesIndex) => {
                if(seriesItem.stack === 'one'){
                    oneList.push(seriesItem);
                } else {
                    twoList.push(seriesItem);
                }
            })
            // console.log(oneList,twoList,'======');
            let oneList2 = JSON.parse(JSON.stringify(oneList)).reverse();
            if(oneList.length > 0){
                // 设置柱条顶层圆角
                for (let i in oneList2[0].data) { // 遍历所有的x轴数据(总数为n),为单根柱条提供data下标（0 ~ n-1）
                    for (let item of oneList2) {  //遍历单根柱条
                        if (Number(item.data[i].value) > 0) { // 找到第一个不为0的值，为该项添加itemStyle
                        //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                            item.data[i]['itemStyle'] = { barBorderRadius: [50, 50, 0, 0] };
                            break;  //退出该柱条的循环
                        }
                    }
                }
                oneList2 = oneList2.reverse();
                for (let i in oneList2[oneList2.length-1].data) {
                    for (let index = 0; index < oneList2.length; index++) {
                        let item = oneList2[index];
                        if (Number(item.data[i].value) > 0) { // 找到第一个不为0的值，为该项添加itemStyle
                            //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                            if(item.data[i]['itemStyle']){
                                item.data[i]['itemStyle'] = { barBorderRadius:  [50, 50, 50, 50] };
                            } else {
                                item.data[i]['itemStyle'] = { barBorderRadius:  [0, 0, 50, 50] };
                            }
                            break;  //退出该柱条的循环
                        }

                    }

                }
            }
            let twoList2 = JSON.parse(JSON.stringify(twoList)).reverse();
            if(twoList.length > 0){
                for (let i in twoList2[0].data) { // 遍历所有的x轴数据(总数为n),为单根柱条提供data下标（0 ~ n-1）
                    for (let item of twoList2) {  //遍历单根柱条
                        if (Number(item.data[i].value) > 0) { // 找到第一个不为0的值，为该项添加itemStyle
                        //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                            item.data[i]['itemStyle'] = { barBorderRadius: [50, 50, 0, 0] };
                            break;  //退出该柱条的循环
                        }
                    }
                }
                twoList2 = twoList2.reverse();
                for (let i in twoList2[twoList2.length-1].data) {
                    for (let item of twoList2) {  //遍历单根柱条
                        if (Number(item.data[i].value) > 0) { // 找到第一个不为0的值，为该项添加itemStyle
                        //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                            if(item.data[i]['itemStyle']){
                                item.data[i]['itemStyle'] = { barBorderRadius:  [50, 50, 50, 50] };
                            } else {
                                item.data[i]['itemStyle'] = { barBorderRadius:  [0, 0, 50, 50] };
                            }
                            break;  //退出该柱条的循环
                        }
                    }
                }
            }
            // let _oneList3 = JSON.parse(JSON.stringify(oneList2)).reverse();
            // let _twoList3 = JSON.parse(JSON.stringify(twoList2)).reverse();
            series = [...oneList2,...twoList2]
            // console.log(series,'会话及问题解决情况');
            // console.log(series,'处理后的series');
            let option = {};
            if(this.sessionTotalData.totalNum === 0 && this.questionTotalData.totalNum === 0){
                // option = {
                //     title: {
                //         text: '暂无数据',
                //         x: 'center',
                //         y: 'center',
                //         textStyle: {
                //             fontSize: 16,
                //             fontWeight: 'normal',
                //             color:'#A9B3C6'
                //         }
                //     }
                // }
            } else {
                option = {
                    // legend: {
                    //     data: ['bar', 'bar2', 'bar3', 'bar4'],
                    //     left: '10%'
                    // },
                    // 机器人独立解决会话数 转工单解决会话数  转IM人工解决会话数
                    // 机器人独立解决问题数  转工单解决问题数 转IM人工解决问题数
                    // color: ["#458FFF", "#89B8FF", "#BBD6FF", "#FF8F97", "#FFBABF", "#FFDDDF"],
                    // color:color,
                    xAxis: {
                        data: xAxisData,
                        axisLine: { onZero: true },
                        splitLine: { show: false },
                        splitArea: { show: false },
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    yAxis: {
                        type: 'value',
                        name: '',
                        // min: 0,
                        splitLine: { // 设置网格线
                            show:true,
                            lineStyle:{
                                color:"#E0E6F7",

                            }
                        },
                        // max: 250,
                        // interval: 50,
                        axisLine: {
                            show: false, //隐藏y轴
                        },
                        axisTick: {
                            show: false // 不显示坐标轴刻度线
                        },
                        axisLabel: {
                            formatter: '{value}',
                            color: '#A9B3C6',
                        },
                        itemStyle: {
                            normal: {
                                //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                                barBorderRadius: [50, 50, 50, 50]
                            }
                        }
                    },
                    tooltip: {
                        show: true,
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            },
                        },
                        textStyle:{
                            align:'left'
                        }

                    },
                    grid: {
                        show: false,
                        top: '20',
                        right: '5%',
                        bottom: '40',
                        left: '5%'
                    },
                    series: [...series],
                };
            }
            option && this.myChart.setOption(option);
            window.addEventListener("resize", () => {
                this.myChart.resize();
            });
        }
    },
    mounted() {
        // this.iniEchart([1, 2, 3]);
    }
}
</script>
<style lang="less" scoped>
@import "./../../../assets/less/statistics/statisticsCommon.less";
#questionssolution {
    height: 424px;
    background: #FFFFFF;
    box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
    border-radius: 5px;
    margin-top: 14px;
    overflow: hidden;
    .asksta-simple-title {
        .ast-left {
            width: 160px ;
        }
        .ast-right {
            width: calc(100% - 180px);
            flex: auto;
            flex-wrap: wrap;
            align-items: stretch;
            justify-content: center;

            display: flex;
            flex-wrap: wrap;
            align-items: stretch;

            .ast-right-cell-bg-lb {
                background-color: rgba(54, 106, 255, 0.2);
                padding: 0 6px;
                border-radius: 10px;
                font-size: 14px;
            }

            .ast-right-cell-bg-lp {
                background-color: rgba(255, 114, 114, 0.2);
                padding: 0 6px;
                border-radius: 10px;
                font-size: 14px;
            }

            .ast-right-cell {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: nowrap;
                margin-right: 16px;
                font-size: 12px;
                line-height: 20px;
                width: 260px;
                text-align: left;
                .arc-icon {
                    margin-right: 4px;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    cursor: pointer;

                    &.total-session{
                         background: #366AFF;
                    }
                    &.total-question{
                         background: #FF7272;
                    }
                    &.color-b-1 {
                        background: #366AFF;
                    }
                    &.color-b-08 {
                        background: #458FFF;
                    }
                    &.color-b-04 {
                        background: #89B8FF;
                    }
                    &.color-b-02 {
                        background: #BBD6FF;
                    }
                    &.color-p-1 {
                        background: #FF7272;
                    }
                    &.color-p-08 {
                        background: #FF8F97;
                    }
                    &.color-p-04 {
                        background: #FFBABF;
                    }
                    &.color-p-02 {
                        background: #FFDDDF;
                    }
                    &.color-gray {
                        background: #A9B3C6;
                    }
                }

                .arc-name {
                    cursor: pointer;
                    color: #000000;
                    // font-weight: 600;
                    white-space: nowrap;
                }

                .arc-num {
                    color: #000000;
                    // font-weight: 600;
                    margin-right: 8px;
                    margin-left: 6px;
                     white-space: nowrap;
                }

                .arc-per-pre {
                    color: #A9B3C6;
                    white-space: nowrap;
                }

                .arc-type {
                    margin-left: 6px;
                    margin-right: 4px;
                }

                .arc-type-num-box {
                    height: 16px;
                    line-height: 16px;
                    background: #F5F7FB;
                    border-radius: 8px;
                    padding: 0 3px;
                    .arc-type-icon-up {
                        color: #FE5965;
                        i {
                            font-size: 12px;
                        }
                    }
                    .arc-type-icon-down {
                        color: #09DDD5;

                        i {
                            font-size: 12px;
                        }
                    }
                    .arc-type-num{
                        margin-left: 3px;
                        font-size: 12px;
                    }
                }
                &.ast-right-cell-bg-lp,&.ast-right-cell-bg-lb{
                    width: auto;
                    .arc-icon,.arc-name{
                        cursor: default;
                    }
                }
            }

            .ast-right-bottom{
                margin-top: 0 !important;
            }

        }
    }
    .asksta-receivenum-char{
        margin-top: 30px;
    }
}
</style>

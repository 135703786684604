<template>
    <div id="receivenum">
        <div class="asksta-simple-title">
            <div class="ast-left" :style="$i18n.locale === 'en' ? 'width:160px' : ''">
                <span class="ast-left-name">{{$t('statisticsUp.receiveNum.title')}}</span>
                <span class="ast-left-icon">
                    <el-tooltip class="item" effect="dark" placement="top-start">
                        <div slot="content">
                            <div style="font-weight:700;">{{$t('statisticsUp.totalNumberOfReceptions')}}：</div>
                            <div>{{$t('statisticsUp.receiveNum.tip1')}}</div>
                            <!-- <div>进入机器人但未进行咨询，不计算人数。</div> -->
                            <div>{{$t('statisticsUp.receiveNum.tip2')}}</div>
                            <div>{{$t('statisticsUp.receiveNum.tip3')}}</div>
                            <div>{{$t('statisticsUp.receiveNum.tip11')}}</div>
                            <div>{{$t('statisticsUp.receiveNum.tip12')}}</div>
                            <div class="static-empty-dom"></div>
                            <div style="font-weight:700;">{{$t('statisticsUp.sessionsNumber')}}：</div>
                            <div>{{$t('statisticsUp.receiveNum.tip4')}}</div>
                            <div>{{$t('statisticsUp.receiveNum.tip5')}}</div>
                            <div>{{$t('statisticsUp.receiveNum.tip6')}}</div>
                            <div>{{$t('statisticsUp.receiveNum.tip7')}}</div>
                            <div class="static-empty-dom"></div>
                            <div v-if="activeScopeType == 'BOT' && (activeScopeValue == '02361f2526444b7b9dc6f93b15c700d0' || activeScopeValue == '9ffea43a274342f4acaba2761678dc1b') " style="font-weight:700;">{{$t('statisticsUp.botSessionResolutionRate')}}：</div>
                            <div v-if="activeScopeType == 'BOT' && (activeScopeValue == '02361f2526444b7b9dc6f93b15c700d0' || activeScopeValue == '9ffea43a274342f4acaba2761678dc1b') ">{{$t('statisticsUp.topNumber.tip5_li')}}</div>
                            <div v-if="activeScopeType == 'BOT' && (activeScopeValue == '02361f2526444b7b9dc6f93b15c700d0' || activeScopeValue == '9ffea43a274342f4acaba2761678dc1b') ">{{$t('statisticsUp.topNumber.tip6_li')}}</div>

                            <div v-if="!(activeScopeType == 'BOT' && (activeScopeValue == '02361f2526444b7b9dc6f93b15c700d0' || activeScopeValue == '9ffea43a274342f4acaba2761678dc1b') )" style="font-weight:700;">{{$t('statisticsUp.laborConversionRate')}}：</div>
                            <div v-if="!(activeScopeType == 'BOT' && (activeScopeValue == '02361f2526444b7b9dc6f93b15c700d0' || activeScopeValue == '9ffea43a274342f4acaba2761678dc1b') )">{{$t('statisticsUp.receiveNum.tip8')}}</div>
                            <div v-if="!(activeScopeType == 'BOT' && (activeScopeValue == '02361f2526444b7b9dc6f93b15c700d0' || activeScopeValue == '9ffea43a274342f4acaba2761678dc1b') )">{{$t('statisticsUp.receiveNum.tip9')}}</div>
                        </div>
                        <i class="el-icon-warning-outline"></i>
                    </el-tooltip>
                </span>
            </div>
            <div class="ast-right" v-if="!(receptionData.totalNum === 0 && sessionData.totalNum === 0 &&  parseInt(artificialData.totalRate) == 0)">
                <div class="ast-right-cell">
                    <div @click="switchItem(1)" :class="['arc-icon',fActive ? 'color-b' : 'color-gray']"></div>
                    <span @click="switchItem(1)" class="arc-name">{{$t('statisticsUp.totalNumberOfReceptions')}}:</span>
                    <span class="arc-num">{{receptionData.totalNum}}</span>
                    <span class="arc-per-pre">{{$t('statisticsUp.ratio')}}</span>
                    <ration-text :data="receptionData"></ration-text>
                </div>
                <div class="ast-right-cell">
                    <div @click="switchItem(4)" :class="['arc-icon',f1Active ? 'color-bl' : 'color-gray']"></div>
                    <span @click="switchItem(4)" class="arc-name">{{$t('statisticsUp.receiveNum.effective')}}:</span>
                    <span class="arc-num">{{receptionData.validRecordNum}}</span>
                    <span class="arc-per-pre">{{$t('statisticsUp.ratio')}}</span>
                    <ration-text :data="{ratio: receptionData.validRecordRatio ? receptionData.validRecordRatio : '0.00%' , ratioType: setRatioType(receptionData.validRecordRatio) }"></ration-text>
                </div>
                <div class="ast-right-cell">
                    <div @click="switchItem(5)" :class="['arc-icon',f2Active ? 'color-n' : 'color-gray']"></div>
                    <span @click="switchItem(5)" class="arc-name">{{$t('statisticsUp.receiveNum.invalid')}}:</span>
                    <span class="arc-num">{{receptionData.invalidRecordNum}}</span>
                    <span class="arc-per-pre">{{$t('statisticsUp.ratio')}}</span>
                    <ration-text :data="{ratio: receptionData.inValidRecordRatio ? receptionData.inValidRecordRatio : '0.00%', ratioType: setRatioType(receptionData.inValidRecordRatio)}"></ration-text>
                </div>
                <div class="ast-right-cell">
                    <div @click="switchItem(2)" :class="['arc-icon',sActive ? 'color-g' : 'color-gray']"></div>
                    <span @click="switchItem(2)" class="arc-name">{{$t('statisticsUp.sessionsNumber')}}:</span>
                    <span class="arc-num">{{sessionData.totalNum}}</span>
                    <span class="arc-per-pre">{{$t('statisticsUp.ratio')}}</span>
                    <ration-text :data="sessionData"></ration-text>
                </div>
                <div v-if="activeScopeType == 'BOT' && (activeScopeValue == '02361f2526444b7b9dc6f93b15c700d0' || activeScopeValue == '9ffea43a274342f4acaba2761678dc1b') " class="ast-right-cell">
                    <div @click="switchItem(3)" :class="[' artificial',tActive ? 'color-o' : 'color-gray']">
                        <div class="left"></div>
                        <div class="cir"></div>
                        <div class="right"></div>
                    </div>
                    <span @click="switchItem(3)" class="arc-name">{{$t('statisticsUp.botSessionResolutionRate')}}:</span>
                    <span class="arc-num">{{artificialData.totalRate && artificialData.totalRate != 0 ? (Number(artificialData.totalRate)*100).toFixed(2) + "%"  : '0.00%'}}</span>
                    <span class="arc-per-pre">{{$t('statisticsUp.ratio')}}</span>
                    <ration-text :data="artificialData"></ration-text>
                </div>
                <div v-if="!(activeScopeType == 'BOT' && (activeScopeValue == '02361f2526444b7b9dc6f93b15c700d0' || activeScopeValue == '9ffea43a274342f4acaba2761678dc1b') )" class="ast-right-cell">
                    <div @click="switchItem(3)" :class="[' artificial',tActive ? 'color-o' : 'color-gray']">
                        <div class="left"></div>
                        <div class="cir"></div>
                        <div class="right"></div>
                    </div>
                    <span @click="switchItem(3)" class="arc-name">{{$t('statisticsUp.laborConversionRate')}}:</span>
                    <span class="arc-num">{{artificialData.totalRate && artificialData.totalRate != 0 ? (Number(artificialData.totalRate)*100).toFixed(2) + "%"  : '0.00%'}}</span>
                    <span class="arc-per-pre">{{$t('statisticsUp.ratio')}}</span>
                    <ration-text :data="artificialData"></ration-text>
                </div>
            </div>
        </div>
        <div style="height: 350px" v-if="receptionData.totalNum == 0 && sessionData.totalNum == 0 &&  parseInt(artificialData.totalRate) == 0">
            <no-data-echarts></no-data-echarts>
        </div>
        <div class="asksta-receivenum-char" v-show="!(receptionData.totalNum == 0 && sessionData.totalNum == 0 &&  parseInt(artificialData.totalRate) == 0)">
            <div style="height: 350px" id="asksta-receivenum-char"></div>
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
import RationText from './ratio-text.vue'
import NoDataEcharts from './noDataEcharts.vue';
import transferEchartsIcon from '../../../assets/images/transfer-echarts-icon.png'
export default {
    components:{RationText,NoDataEcharts},
    data() {
        return {
            fActive: true,
            f1Active: true,
            f2Active: true,
            sActive: true,
            tActive: true,
            myChart: null,
            isInitNum:0,
            showArr:[],
            yAxisIndex:1
        }
    },
    props:{
        receptionData:{
            type:Object,
            default(){
                return {}
            }
        },
        sessionData:{
            type:Object,
            default(){
                return {}
            }
        },
        artificialData:{
            type:Object,
            default(){
                return {}
            }
        },
        loading:{
            type:Boolean,
            default:true
        },
        isInit:{
            type:Boolean,
            default:true
        },
        activeScopeType: {
            type: String,
            default: ""
        },
        activeScopeValue: {
            type: String,
            default: ""
        }
    },
    watch:{
        loading(n){
            if(n){
               this.isInitNum = 0;
            }
        },
        receptionData:{
            handler(n){
                this.isInitNum++
            },
            deep:true
        },
        sessionData:{
            handler(n){
                this.isInitNum++
            },
            deep:true
        },
        artificialData:{
            handler(n){
                this.isInitNum++
            },
            deep:true
        },
        isInit:{
            handler(n){
                if(n){
                    this.showArr = [1, 2, 3, 4, 5];
                }
            },
            immediate:true
        },
        isInitNum(n){
            if(n === 3){
                this.iniEchart(this.isInit ? [1, 2, 3, 4 ,5] : this.showArr);
            }
        },

    },
    methods: {
        setRatioType(num) {
            if(num) {
                if(num.indexOf('-') !== -1) {
                    return '-'
                } else {
                    return ''
                }
            } else {
                return ''
            }
        },
        handlerBorderRadis(list){
            // 只有一个类型时 , 四个角设置为圆角
            if(list.length === 1){
                list[0].itemStyle = {
                    normal: {
                        //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                        barBorderRadius: [50, 50, 50, 50]
                    }
                }
            } else if (list.length === 2){
                // 有多个类型时
                list[0].itemStyle = {
                    normal: {
                        //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                       barBorderRadius: [0, 0, 50, 50]
                    }
                }
                list[1].itemStyle = {
                    normal: {
                        //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                        barBorderRadius: [50, 50, 0, 0]
                    }
                }
            }
            return list;
        },
        switchItem(index) {
            if (index == 1) {
                if(this.showArr.length === 1 && this.fActive){
                    this.$message.warning(this.$t('statisticsUp.receiveNum.tip10'));
                    return false;
                } else {
                    this.fActive = !this.fActive;
                }

            }
            if (index == 2) {
                if(this.showArr.length === 1 && this.sActive){
                    this.$message.warning(this.$t('statisticsUp.receiveNum.tip10'));
                    return false;
                } else {
                    this.sActive = !this.sActive;
                }
            }
            if (index == 3) {
                if(this.showArr.length === 1 && this.tActive){
                    this.$message.warning(this.$t('statisticsUp.receiveNum.tip10'));
                    return false;
                } else {
                    this.tActive = !this.tActive;
                }
            }
            if (index == 4) {
                if(this.showArr.length === 1 && this.f1Active){
                    this.$message.warning('至少保留一个');
                    return false;
                } else {
                    this.f1Active = !this.f1Active;
                }
            }
            if (index == 5) {
                if(this.showArr.length === 1 && this.f2Active){
                    this.$message.warning('至少保留一个');
                    return false;
                } else {
                    this.f2Active = !this.f2Active;
                }
            }
            let arr = [];
            if (this.fActive) {
                arr.push(1);
            }
            if (this.sActive) {
                arr.push(2);
            }
            if (this.tActive) {
                arr.push(3);
            }
            if (this.f2Active) {
                arr.push(5);
            }
            if (this.f1Active) {
                arr.push(4);
            }
            this.showArr = arr;
            this.iniEchart(arr);
        },
        iniEchart(showArr) {
            this.isInitNum = 0;
            let data1 = [],data2 = [],data3 = [], data4 = [],data5 = [], date = [];
            this.receptionData.data && this.receptionData.data.length > 0 && this.receptionData.data.forEach((item,index) => {
                date.push(item['日期']);
                data1.push(item['访问用户']);
            })
            this.receptionData.validRecordData && this.receptionData.validRecordData.length > 0 && this.receptionData.validRecordData.forEach((item,index) => {
                data4.push(item['有效访问用户']);
            })
            this.receptionData.invalidRecordData && this.receptionData.invalidRecordData.length > 0 && this.receptionData.invalidRecordData.forEach((item,index) => {
                data5.push(item['无效访问用户']);
            })
            this.sessionData.data && this.sessionData.data.length > 0 && this.sessionData.data.forEach((item,index) => {
                data2.push(item['接待会话数']);
            })

            if(this.artificialData.data && this.artificialData.data.length > 0) {
                date.forEach(d =>{
                    let index = this.artificialData.data.findIndex(art => { return art.time == d })
                    if(index !== -1) {
                        data3.push(this.artificialData.data[index].transferRate)
                    } else {
                        data3.push(0)
                    }
                })
            } else {
                date.forEach(d =>{
                    data3.push(0)
                })
            }
            var chartDom = document.getElementById('asksta-receivenum-char');
            if (this.myChart != null) {
                this.myChart.clear();
            }

            this.myChart = echarts.init(chartDom);
            if(this.fActive || this.sActive){
                this.yAxisIndex = 1;
            } else {
                 this.yAxisIndex = 0;
            }
            let askYAxis = [{
                type: 'value',
                name: '',
                min: 0,
                splitLine: false,
                axisLine: {
                    show: false, //隐藏y轴
                },
                axisTick: {
                    show: false // 不显示坐标轴刻度线
                },
                axisLabel: {
                    formatter: '{value}',
                    color: '#A9B3C6',
                }
            },
            {
                type: 'value',
                name: '',
                min: 0,
                splitLine: false,
                axisLine: {
                    show: false, //隐藏y轴
                },
                axisTick: {
                    show: false // 不显示坐标轴刻度线
                },
                axisLabel: {
                    formatter: function(value){
                        return value*100+'%'
                    },
                    color: '#A9B3C6',
                },

            }];
            let askSeries = [
                {
                    show: false,
                    name: this.$t('statisticsUp.totalNumberOfReceptions'),
                    type: 'bar',
                    barMaxWidth: '20px',
                    tooltip: {
                        valueFormatter: function (value) {
                            return value;
                        }
                    },
                    data:data1,
                    itemStyle: {
                        normal: {
                            //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                            barBorderRadius: [50, 50, 50, 50]
                        }
                    },

                },
                {
                    show: false,
                    name: this.$t('statisticsUp.receiveNum.effective'),
                    type: 'bar',
                    stack: 'Ad',
                    barMaxWidth: '20px',
                    data: data4,
                    itemStyle: {
                        normal: {
                            //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                            barBorderRadius: [0, 0, 50, 50]
                        }
                    }
                },
                {
                    show: false,
                    name: this.$t('statisticsUp.receiveNum.invalid'),
                    type: 'bar',
                    stack: 'Ad',
                    barMaxWidth: '20px',
                    data: data5,
                    itemStyle: {
                        normal: {
                            //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                            barBorderRadius: [50, 50, 0, 0]
                        }
                    }
                },
                {
                    show: false,
                    name: this.$t('statisticsUp.sessionsNumber'),
                    type: 'bar',
                    barMaxWidth: '20px',
                    data:data2,
                    itemStyle: {
                        normal: {
                            //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                            barBorderRadius: [50, 50, 50, 50]
                        }
                    }
                },
                {
                    name: (this.activeScopeType == 'BOT' && (this.activeScopeValue == '02361f2526444b7b9dc6f93b15c700d0' || this.activeScopeValue == '9ffea43a274342f4acaba2761678dc1b'))?this.$t('statisticsUp.botSessionResolutionRate'):this.$t('statisticsUp.laborConversionRate'),
                    type: 'line',
                    smooth: true,
                    yAxisIndex: this.yAxisIndex,
                    itemStyle: {
                        normal: {
                            lineStyle: {
                            width:4 // 设置线条粗细
                            }
                        }
                    },
                    data:data3,
                    symbol: 'image://' + transferEchartsIcon,
                    showAllSymbol: false,
                    symbolSize: 24,
                }
            ]
            console.log(this.yAxisIndex,data3, date, 'this.yAxisIndex');
            let askColor = ['#458FFF', '#89B8FF', '#FF8F97', '#09DDD5', '#FF9555']
            let yAxis = [], series = [], color = [];

            if (showArr.indexOf(1) != -1 || showArr.indexOf(2) != -1) {
                yAxis.push(askYAxis[0]);
                if (showArr.indexOf(1) != -1) {
                    series.push(askSeries[0])
                    color.push('#458FFF');
                }
                if (showArr.indexOf(4) != -1) {
                    series.push(askSeries[1])
                    color.push('#89B8FF');
                }
                if (showArr.indexOf(5) != -1) {
                    series.push(askSeries[2])
                    color.push('#FF8F97');
                }
                if (showArr.indexOf(2) != -1) {
                    series.push(askSeries[3])
                    color.push('#09DDD5');
                }
            }
            if (showArr.indexOf(3) != -1) {
                yAxis.push(askYAxis[1])
                series.push(askSeries[4])
                color.push('#FF9555');
            }


            // series = [...oneList2]

            // 设置网格线
            yAxis[0].splitLine = {
                show:true,
                lineStyle:{
                    color:"#E0E6F7",

                }
            };

            // let oneList = [];
            // series.forEach((seriesItem,seriesIndex) => {
            //     if(seriesItem.stack === 'Ad'){
            //         oneList.push(seriesItem);
            //     }
            // })
            // let oneList2 = JSON.parse(JSON.stringify(oneList)).reverse();
            // if(oneList.length > 0){
            //     console.log(434, oneList2[0].data);
            //     // 设置柱条顶层圆角
            //     for (let i in oneList2[0].data) { // 遍历所有的x轴数据(总数为n),为单根柱条提供data下标（0 ~ n-1）
            //         for (let item of oneList2) {  //遍历单根柱条
            //             if (Number(item.data[i]) > 0) { // 找到第一个不为0的值，为该项添加itemStyle
            //             //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]  因为是reverse 所以设置的是反方向
            //                 console.log( item.data[i]);
            //                 item.data[i]['itemStyle'] = { barBorderRadius: [50, 50, 0, 0] };
            //                 break;  //退出该柱条的循环
            //             }
            //         }
            //     }
            //     oneList2 = oneList2.reverse();
            //     for (let i in oneList2[oneList2.length-1].data) {
            //         for (let index = 0; index < oneList2.length; index++) {
            //             let item = oneList2[index];
            //             console.log(449, item.data[i].value);
            //             if (Number(item.data[i]) > 0) { // 找到第一个不为0的值，为该项添加itemStyle
            //                 //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]  因为是reverse 所以设置的是反方向
            //                 if(item.data[i]['itemStyle']){
            //                     item.data[i]['itemStyle'] = { barBorderRadius:  [50, 50, 50, 50] };
            //                 } else {
            //                     item.data[i]['itemStyle'] = { barBorderRadius:  [0, 0, 50, 50] };
            //                 }
            //                 break;  //退出该柱条的循环
            //             }

            //         }

            //     }
            // }

            // series[1] = oneList2[0];
            // series[2] = oneList2[1];

            let option = {};
            if(this.receptionData.totalNum === 0 &&this.sessionData.totalNum === 0 &&  parseInt(this.artificialData.totalRate) == 0){
                // option = {
                //     title: {
                //         text: '暂无数据',
                //         x: 'center',
                //         y: 'center',
                //         textStyle: {
                //             fontSize: 16,
                //             fontWeight: 'normal',
                //             color:'#A9B3C6'
                //         }
                //     }
                // }
            } else {
                option = {
                    color: color,
                    tooltip: {
                        show: true,
                        trigger: 'axis',
                        textStyle:{
                            align:'left'
                        },
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                        },
                        formatter: function (params) {
                            let str = params[0].name + "<br />";
                            params.forEach((item,index) => {
                                if(item.seriesName === '转人工率' || item.seriesName === '机器人会话解决率'){
                                    let num = ((item.data * 100).toFixed(2)) + '%';
                                    str += '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:'+item.color+'"></span>' + item.seriesName + " : " + num + "<br />";
                                } else {
                                    str += '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:'+item.color+'"></span>' + item.seriesName + " : " + item.data + "<br />";
                                }

                            });
                            return str;
                        }
                    },
                    grid: {
                        show: false,
                        top: '20',
                        right: '5%',
                        bottom: '40',
                        left: '5%'
                    },
                    xAxis: {
                        type: 'category',
                        data:date,
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    yAxis: [...yAxis],
                    series: [...series],
                };
            }
            option && this.myChart.setOption(option);

            window.addEventListener("resize", () => {
                this.myChart.resize();
            });
        }
    },
}
</script>
<style lang="less" scoped>
@import "./../../../assets/less/statistics/statisticsCommon.less";
#receivenum {
    height: 424px;
    background: #FFFFFF;
    box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
    border-radius: 5px;
    .asksta-simple-title {
        .ast-left {
            width: 90px ;
        }
        .ast-right {
            width: calc(100% - 115px);
            justify-content: center;
            align-items: center;
            flex: auto;
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;

            .ast-right-cell {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-right: 30px;

                .arc-icon {
                    margin-right: 4px;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    &.color-b {
                        background: #458FFF;
                    }

                    &.color-bl {
                        background: #89B8FF;
                    }

                    &.color-n {
                        background: #FF8F97;
                    }

                    &.color-g {
                        background: #09DDD5;
                    }

                    &.color-o {
                        background: #FF9555;
                    }

                    &.color-gray {
                        background: #A9B3C6;
                    }
                }
                .artificial{

                    display: flex;
                    align-items: center;
                    margin-right: 4px;
                    cursor: pointer;
                    .left,.right{
                        width: 4px;
                        height: 2px;

                    }
                    .cir{
                        width: 8px;
                        height: 8px;
                        border: 2px solid #FF9555;
                        border-radius: 50%;
                    }
                    &.color-o{
                        color: #FF9555;
                        .left,.right{
                            background-color: #FF9555;
                        }
                        .cir{
                            border-color: #FF9555;
                        }
                    }
                    &.color-gray{
                        color: #A9B3C6;
                        .left,.right{
                            background-color: #A9B3C6;
                        }
                        .cir{
                            border-color: #A9B3C6;
                        }
                    }
                }

                .arc-name {
                    cursor: pointer;
                    color: #000000;
                    // font-weight: 600;
                }

                .arc-num {
                    color: #000000;
                    // font-weight: 600;
                    margin-right: 8px;
                    margin-left: 6px;
                }

                .arc-per-pre {
                    color: #A9B3C6;
                }

                .arc-type {
                    margin-left: 6px;
                    margin-right: 4px;

                }

            }

        }
    }
}
</style>
